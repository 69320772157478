import styles from "./error.module.css";
import ErrorPage from "./ErrorPage";

export function ErrorFallback({ error }) {
  return (
    <div className={styles.errorFallback}>
      <ErrorPage statusCode={500} message={error?.message} />
    </div>
  );
}
