import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./Loading.module.css";

const LoadingFullpage = (props) => {
  return (
    <div {...props} className={styles.fullPageCenter}>
      <Spin
        tip="Loading..."
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      />
    </div>
  );
};

export default LoadingFullpage;
