import numeral from "numeral";
import crypto from "crypto";

export function debounce(fn, wait) {
  let timeout;
  return (...args) => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(fn, wait, ...args);
  };
}

export const keyify = (obj, prefix = "") =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    } else if (typeof obj[el] === "object" && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + ".")];
    }
    return [...res, prefix + el];
  }, []);

export const formatNumber = (value) => {
  if (!value) {
    return value;
  }
  const arr = value.toString().split(".");
  let result = numeral(arr[0]).format("0,0");
  result += arr[1] ? `.${arr[1]}` : "";
  return result;
};

export const getHash = (text) => {
  return crypto.createHash("md5").update(text.trim()).digest("hex");
};

export const findDuplicates = (arr) => {
  const result = arr.find((item, index) => {
    if (arr.indexOf(item) != index) return item;
  });
  return result || false;
};
