import { Suspense } from "react";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { lazy } from "@loadable/component";
import { LoadingFullpage } from "features/Loading";
import { transformValidateMessages } from "utils/form";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "@sentry/react";
import { ErrorFallback } from "features/Error/ErrorFallback";
import { LOGIN_URI } from "constant";

const AppContainer = lazy(() =>
  import("features/App").then((c) => c.AppContainer)
);
const PlayContainer = lazy(() =>
  import("features/Play").then((c) => c.PlayContainer)
);
const AdminContainer = lazy(() =>
  import("features/Admin").then((c) => c.AdminContainer)
);
const SSOContainer = lazy(() =>
  import("features/SSO").then((c) => c.SSOContainer)
);
const CodeEntryContainer = lazy(() =>
  import("features/CodeEntry").then((c) => c.CodeEntryContainer)
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, cacheTime: 0, retry: 3 },
  },
});

function AppContent() {
  const { t } = useTranslation();
  return (
    <AntdConfigProvider
      form={{
        validateMessages: transformValidateMessages(
          t("form.validate_messages", {
            returnObjects: true,
            skipInterpolation: true,
          })
        ),
      }}
    >
      <Router>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path={["/admin", LOGIN_URI]}>
              <AdminContainer />
            </Route>
            <Route path="/play">
              <PlayContainer />
            </Route>
            <Route path="/sso">
              <SSOContainer />
            </Route>
            <Route path="/code-entry">
              <CodeEntryContainer />
            </Route>
            <Route path="*">
              <AppContainer />
            </Route>
          </Switch>
        </QueryClientProvider>
      </Router>
    </AntdConfigProvider>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Suspense fallback={<LoadingFullpage />}>
        <ErrorBoundary
          fallback={ErrorFallback}
          beforeCapture={(scope) => {
            scope.setTag("errorBoundary", "App.jsx");
          }}
        >
          <AppContent />
        </ErrorBoundary>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
