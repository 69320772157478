import { useTranslation } from "react-i18next";
import { Result } from "antd";
import { Helmet } from "react-helmet-async";
import styles from "./error.module.css";
const ErrorPage = ({ statusCode, message }) => {
  const { t } = useTranslation();

  const defaultMessage = {
    403: t("common.403.message"),
    404: t("common.404.message"),
    500: t("common.500.message"),
  };

  return (
    <>
      <Helmet>
        <title>{`${statusCode}`}</title>
      </Helmet>
      <Result
        className={styles.errorResult}
        title={statusCode}
        subTitle={message || defaultMessage[statusCode]}
      />
    </>
  );
};

export default ErrorPage;
