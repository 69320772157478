import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: "local",
  lookup() {
    return window.localStorage.getItem("i18nextLng") || "ja";
  },
  cacheUserLanguage(lng) {
    window.localStorage.setItem("i18nextLng", lng);
  },
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection: {
      order: ["local"],
    },
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
