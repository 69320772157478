import { message } from "antd";
import i18n from "i18n";
import dlv from "dlv";
import { dset } from "dset";
import { keyify } from "./js-utils";

export const onFinishFailed = (e) => {
  message.error(e.errorFields[0].errors[0]);
};

// sometimes initial value is string (suc as from API .imageUrl)
// in which case we map to array
export const getUploadValueProps = (value) => {
  if (typeof value === "string") {
    return {
      fileList: [
        {
          uid: "-1",
          status: "done",
          url: value, // is imageUrl
          name: value.substring(value.lastIndexOf("/") + 1),
        },
      ],
    };
  } else return { fileList: value }; // maybe array of fileList
};

// Always use fileList (array) as value
export const getUploadValueFromEvent = (value) => {
  // FIXME: possibly upstream bug: https://github.com/ant-design/ant-design/issues/2423
  // flaky behavior here!
  // BUG: React dont know fileList has changed

  return value.fileList;
};

export const getUploadValueFromEventWithValidate = (
  originalValueFn,
  { fileTypes, maxFileSize }
) => {
  return (value) => {
    if (value.fileList.length === 0) return [];
    const { file } = value;
    if (fileTypes && !fileTypes.includes(file.type)) {
      // validate file type
      message.error(
        i18n.t("error.file_unaccepted", {
          fileTypes: fileTypes.map((ft) => ft.split("/")[1]).join(", "),
        })
      );
      // antd acts weird if we return falsy value
      return originalValueFn() || [];
    }
    if (maxFileSize && file.size > maxFileSize) {
      message.error(
        i18n.t("error.file_large", {
          size: `${maxFileSize / 1000000}MB`,
        })
      );
      // antd acts weird if we return falsy value
      return originalValueFn() || [];
    }
    return getUploadValueFromEvent(value);
  };
};

// Return a valid file based on input from onFinish

export const onFinishExtractUpload = (obj) => {
  if (Array.isArray(obj)) {
    // image is array
    // use first image only
    // originFileObj is File Instance: https://developer.mozilla.org/en-US/docs/Web/API/File
    if (obj.length) {
      const originFileObj = obj[0].originFileObj;
      return originFileObj;
    } else {
      // value empty, might have been removed, explicitly set to false so API knows to remove
      return false;
    }
  } else if (typeof obj === "string") {
    // gotten from API, no modify => remove it so we do nothing
    return undefined;
  }
};

export const onImagePreview = (data) => {
  if (data.originFileObj) {
    // FIXME: This WILL cause memory leak without cleanup
    const previewUrl = URL.createObjectURL(data.originFileObj);
    window.open(previewUrl);
  } else if (data.url) {
    window.open(data.url);
  }
  return false;
};

export const transformValidateMessages = (obj) => {
  // transform {{name}} to ${name} for antd form
  const result = {};
  keyify(obj).forEach((key) => {
    let str = dlv(obj, key);
    str = str.split("{{").join("${").split("}}").join("}");
    dset(result, key, str);
  });
  return result;
};
